import * as webcomponents from '../../webcomponents';

import { mountATBListener } from '../events/atb';
import { skipCartDrawer } from '../events/cart';
import { isGem, mountIconOnGemini } from '../platform/gemini';

import { IMountCartDrawer } from '../types';
import {
  CART_CONFIGS,
  DRAWER_ATTRIBUTES,
  SELECTORS,
  WEBCOMPONENTS_CONTROLLERS
} from '../utils/constants';
import { debugConsole } from '../utils/debug';
import { isVulcan, setAttributes } from '../utils/helpers';

export const mountOnIcon = () => {
  const query = isVulcan() ? SELECTORS.cartIconVulcan : SELECTORS.cartIconDrupal;
  const icon = document.querySelector(query);

  if (icon && !icon.hasAttribute('data-mounted')) {
    const controller = icon.getAttribute('data-controller');
    const target = icon.getAttribute('data-controller');
    const actions = icon.getAttribute('data-action');

    setAttributes(icon, {
      'data-mounted': 'true',
      'data-controller': `${controller ? controller : ''} cart-icon`.trimStart(),
      'data-cart-target': `${target ? target : ''} cartButton`.trimStart(),
      'data-action': `${
        actions ? actions : ''
      } click->cart-icon#handleCartIconClick mouseenter->cart-icon#handleMouseEnter setCartIconCount@window->cart-icon#setItemsCount`.trimStart(),
      'data-cart-icon-cart-icon-url-value': '/checkout/viewcart.tmpl'
    });

    const iconCount = SELECTORS.countIconNodesOrder
      .map((selector) => document.querySelector(selector))
      .find(Boolean);

    if (iconCount) {
      setAttributes(iconCount, { 'data-cart-icon-target': 'itemsCount' });
    } else if (isGem()) {
      mountIconOnGemini(icon);
    }
  }

  return icon;
};

export const mountCartDrawer = async (params?: IMountCartDrawer) => {
  if (skipCartDrawer()) {
    return;
  }

  try {
    const { cartDrawerEndpoint = CART_CONFIGS.drawer_route } = params || {};

    return webcomponents.registerController(WEBCOMPONENTS_CONTROLLERS).then(() => {
      if (!mountOnIcon()) {
        debugConsole(
          '[Cart 3.0] Cart icon not found',
          'warn',
          {
            component: 'Cart Icon',
            details: 'Cart icon is necessary to mount cart drawer and set basic interactions'
          },
          new Error('Cart icon not found. Impossible to mount cart drawer.')
        );

        return;
      }

      const cartDrawer = document.createElement(SELECTORS.cartDrawer);
      setAttributes(cartDrawer, {
        ...DRAWER_ATTRIBUTES,
        'data-cart-drawer-data-endpoint-value': cartDrawerEndpoint
      });

      document.body.appendChild(cartDrawer);

      mountATBListener();
      debugConsole('[Cart 3.0] Cart drawer mounted');

      return cartDrawer;
    });
  } catch (error) {
    debugConsole(
      '[Cart 3.0] Unable to mount cart drawer',
      'error',
      {
        component: 'Cart Drawer',
        details: 'An error occurred while trying to mount cart drawer'
      },
      error
    );
  }
};
