import { TENANTS_SAFEGUARDS } from '../tenants';
import { IErrorResponseData, IEventResultData } from '../../types';
import { ALLOWED_ERROR_MSG_KEYS, SELECTORS } from '../../utils/constants';

const getCollectionID = (results: IEventResultData['ac_results']) => {
  for (const result of results) {
    if ('COLLECTION_ID' in result.result && result.result.COLLECTION_ID) {
      return result.result.COLLECTION_ID;
    }
  }
};

const getRecentAddedItem = (resultData: IEventResultData) => {
  const acResultsData = resultData.ac_results;
  const transData = resultData?.trans_data;
  if (acResultsData.length === 1) {
    return acResultsData[0].result;
  } else {
    const recentItemCollectionId = getCollectionID(acResultsData);
    const recentItem = transData?.order?.items?.filter(
      (eachItem) => eachItem.COLLECTION_ID === recentItemCollectionId
    );
    const recentItemSkuId = recentItem?.[0]['sku.SKU_BASE_ID'];
    const filteredAcResult = acResultsData.filter((eachItem) => {
      return (
        eachItem.result?.CARTITEM?.CART_ID !== null &&
        eachItem.result?.CARTITEM?.['sku.SKU_BASE_ID'] === recentItemSkuId
      );
    });
    return filteredAcResult?.[0].result;
  }
};

const getQuantityValue = (
  qtySelectorElement: Element | null,
  skuBaseId: string,
  itemQuantity: number
) => {
  if (qtySelectorElement && qtySelectorElement.textContent) {
    const qty = qtySelectorElement.textContent;
    return parseInt(qty, 10);
  } else {
    const recentAddedItem = document.querySelector(`[${SELECTORS.recentAddedItem}="${skuBaseId}"]`);
    const recentAddedItemPrevQty = recentAddedItem?.getAttribute('data-cart-item-qty-value') || '0';
    const previousItemQuantity = parseInt(recentAddedItemPrevQty, 10);
    const quantity =
      itemQuantity > previousItemQuantity ? itemQuantity - previousItemQuantity : itemQuantity;
    return quantity;
  }
};

export const getErrorMessage = (resultData: IErrorResponseData) => {
  const _resultData = resultData ?? [];
  const messages = Array.isArray(_resultData)
    ? _resultData
    : _resultData.errorObjects
    ? _resultData.errorObjects
    : [];

  if (messages.length > 0) {
    const filteredMessages = messages.filter((message: { key: string }) => {
      if (message && typeof message === 'object' && 'key' in message) {
        return ALLOWED_ERROR_MSG_KEYS.includes(message.key);
      }
      return false;
    });

    const firstMessage = filteredMessages[0] as { text: string };

    if (typeof firstMessage === 'object' && firstMessage?.text) {
      return firstMessage.text;
    }
  }

  return '';
};

export const processRawATBPayload = (resultData: IEventResultData) => {
  let collectionId: number | undefined;
  let quantity = 1;
  let skuId = '';

  const { PREVIOUS_ITEM_QUANTITY = 0, CARTITEM } = getRecentAddedItem(resultData);
  const { ITEM_QUANTITY = 1, 'sku.SKU_BASE_ID': skuBaseId = '' } = CARTITEM;
  // the data response sent in the event triggered by Drupal does not contain the added quantity
  quantity = ITEM_QUANTITY - PREVIOUS_ITEM_QUANTITY;
  skuId = skuBaseId;

  if (
    resultData.coll_info &&
    resultData.coll_info.COLLECTION_TYPE &&
    resultData.trans_data?.order
  ) {
    const COLLECTION_ID = resultData.coll_info.COLLECTION_ID;
    const skuData = (resultData.trans_data.order?.kits ?? []).find(
      (kit) => kit.COLLECTION_ID === COLLECTION_ID
    );
    if (skuData) {
      collectionId = COLLECTION_ID;
      skuId = skuData['sku.SKU_BASE_ID'];
    }
  }

  return { collectionId, quantity, skuId };
};

export const processATBPayloadMethods = (resultData: IEventResultData) => {
  const item = resultData.getItem();
  const allItems = resultData.getAllItems() || [];
  const { COLLECTION_ID, product, ITEM_QUANTITY: itemQuantity = 1 } = item;
  const { sku } = product;
  const { SKU_BASE_ID: skuBaseId = '' } = sku;

  const hasEngraving = item?.coll_info?.COLLECTION_TYPE === 'MONO';

  const qtySelectorElement = document.querySelector(
    `[${SELECTORS.itemSelector}="${skuBaseId}"] ${SELECTORS.qtySelector}`
  );

  const skuId = TENANTS_SAFEGUARDS['EL']['DOUBLE_WEAR'].fn(COLLECTION_ID, allItems) || skuBaseId;

  const collectionId = COLLECTION_ID;
  const quantityValue = getQuantityValue(qtySelectorElement, skuBaseId, itemQuantity);
  const quantity = hasEngraving ? 1 : quantityValue;

  return { collectionId, quantity, skuId };
};
