import { isDrp, mountOnVulcanATB } from '../platform/drupal';
import { isGem, mountOnGeminiATB } from '../platform/gemini';
import { SELECTORS } from '../utils/constants';
import { isVulcan } from '../utils/helpers';

/**
 * This method requires jQuery since Drupal events are triggered using jQuery
 * so far, this is the only way to listen to the addToCart event we have found
 * but probably there are other events that could trigger the cart drawer
 */
export const mountATBListener = () => {
  if (!isVulcan()) {
    const offerElement = document.querySelector(SELECTORS.offerCode);

    isDrp() && mountOnVulcanATB(offerElement);

    isGem() && mountOnGeminiATB();
  }
};
