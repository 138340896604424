import { skipCartDrawer } from '../events/cart';
import { IComponentOverrides } from '../types';
import { SELECTORS } from '../utils/constants';
import { observeMutations } from '../utils/mutations';

/**
 * This method overrides the components inside the cart drawer
 * @param cartDrawer - The cart drawer element
 * @param override - The override object - value and at least one optional override are required
 * @param elementName - The name of the component to override - to override an inner element inside
 * a web component (slotted element) use the slot name (specified by CartDrawerSlotName); to override
 * a web component use the web component name (specified by CartDrawerComponentName)
 * @returns true if the component was found and overriden, false otherwise
 * @example overrideCartDrawerComponent(cartDrawer, { override: { value: 'Best sellers', text: true }, elementName: 'button-checkout' })
 * @example overrideCartDrawerComponent(cartDrawer, { override: {
 * styles: `.cart-button--drawer-cta-4 {
 *   border: solid 1px red;
 *   background: green;
 *   color: black; }`
 * },
 * elementName: 'cart-footer' })
 */
export const overrideCartDrawerComponent = (
  cartDrawer: Element,
  { override, elementName }: IComponentOverrides
) => {
  const {
    value = '',
    attribute = '',
    text = false,
    className = '',
    styles = '',
    mode = 'overlay'
  } = override;
  const queryMode = `[slot="${mode}"]`;
  const query = styles ? `${elementName}${queryMode}` : `${queryMode} [slot="${elementName}"]`;
  const component = cartDrawer.querySelector(query);

  if (component) {
    if (attribute && component.getAttribute(attribute) !== value) {
      component.setAttribute(attribute, value);
    }
    if (text && component.textContent !== value) {
      component.textContent = value;
    }
    if (className && !component.classList.contains(className)) {
      component.classList.add(className);
    }
    if (styles) {
      overrideCartDrawerComponentStyles(component, styles);
    }

    return true;
  }
  return false;
};

/**
 * This method observes the cart drawer and overrides the components
 * once they are rendered. Since the Cart 3.0 drawer is a server side
 * rendered component, we need to override the components everytime the
 * cart drawer is rendered.
 * @param componentOverrides - The overrides array
 * @param element - The cart drawer element
 * @example overrideCartDrawerComponents([
 *    { override: { value: 'Best sellers', text: true }, elementName: 'button-checkout' }
 *    { override: { value: '/products/8431/meilleures-ventes', attribute: 'data-cart-drawer-url-param' }, elementName: 'button-checkout' }
 * ], cart-drawer)
 */
export const overrideCartDrawerComponents = (
  componentOverrides: IComponentOverrides[],
  element?: Element
) => {
  if (skipCartDrawer()) {
    return;
  }

  const cartDrawer = element || document.querySelector(SELECTORS.cartDrawer);

  observeMutations(
    cartDrawer || document.body,
    () => {
      if (cartDrawer) {
        for (const { override, elementName } of componentOverrides) {
          overrideCartDrawerComponent(cartDrawer, { override, elementName });
        }
      }
    },
    { subtree: true }
  );
};

/**
 * This method overrides the styles of a component inside the cart drawer
 * @param component - The component to override - it must be a web component not a slotted element
 * @param styles - The styles to override
 * @example overrideCartDrawerComponentStyles(component, ':root { color: black; }')
 */
export const overrideCartDrawerComponentStyles = (component: Element, styles: string) => {
  if (component.shadowRoot) {
    try {
      const previousStyles = component.shadowRoot.adoptedStyleSheets;
      const overridedStyles = new CSSStyleSheet();
      overridedStyles.replaceSync(styles);
      component.shadowRoot.adoptedStyleSheets = [...previousStyles, overridedStyles];
    } catch {
      // support for old browsers
      const previousStyles = component.shadowRoot.querySelector('style');

      if (previousStyles) {
        previousStyles.innerText += styles;
      }
    }
  }
};
