import { removeLegacyErrorOverlay } from '../actions/remove';
import { IOpenCartEvent } from '../types';
import { SUPPRESSED_PLATFORMS, SUPPRESSED_PAGES } from '../utils/constants';
import { matchLocation } from '../utils/helpers';

export const skipCartDrawer = () => {
  let matchesTenant = false;
  const { pathname, hostname, search: params } = window.location;

  if (params) {
    const tenant = new URLSearchParams(params).get('tenant') || '';
    matchesTenant = matchLocation(SUPPRESSED_PLATFORMS, tenant);
  }

  const shouldSkipMount =
    matchLocation(SUPPRESSED_PAGES, pathname) || matchLocation(SUPPRESSED_PLATFORMS, hostname);

  return shouldSkipMount || matchesTenant;
};

export const failingCartEvent = (errorMessage: string) => {
  const observer = removeLegacyErrorOverlay();

  const detail = {
    blockUpdate: true,
    cartMode: 'modal',
    error: true,
    errorMessage
  };
  const newCartEvent = new CustomEvent('openCart', { detail });
  window.dispatchEvent(newCartEvent);

  setTimeout(() => {
    observer.disconnect();
  }, 500);
};

export const openCartEvent = (params: IOpenCartEvent) => {
  const observer = removeLegacyErrorOverlay();

  const { quantity, collectionId, offerCode, skuId } = params;

  const detail = {
    cartMode: 'modal',
    quantity
  };

  if (skuId) {
    detail['skuId'] = skuId;
  }

  if (offerCode) {
    detail['offerCode'] = offerCode;
  }

  if (collectionId) {
    detail['collectionId'] = collectionId;
  }

  const newCartEvent = new CustomEvent('openCart', { detail });
  window.dispatchEvent(newCartEvent);

  setTimeout(() => {
    observer.disconnect();
  }, 500);
};
