import { mountCartDrawer } from './actions/mount';
import { overrideCartDrawerComponents } from './actions/override';
import { removeLegacyCartDrawer } from './actions/remove';

import { skipCartDrawer } from './events/cart';
import { IGlcart } from './types';

import { fetchCartConfigs } from './utils/configs';
import { SELECTORS } from './utils/constants';
import { debugConsole } from './utils/debug';
import { forceMount } from './utils/helpers';

declare global {
  interface Window {
    _glcart: IGlcart;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generic: any;
  }
}
const initializeCartDrawer = async (cartDrawerEndpoint: string) =>
  removeLegacyCartDrawer() && (await mountCartDrawer({ cartDrawerEndpoint }));

const setWindowMethods = () =>
  (window._glcart = { mountCartDrawer, overrideCartDrawerComponents, removeLegacyCartDrawer });

const cartDrawerInjection = async () => {
  const { aud_seg_allow, cart_drawer, cart_drawer_automount, has_audseg, drawer_route } =
    await fetchCartConfigs();

  if (forceMount()) {
    return initializeCartDrawer(drawer_route);
  }

  const isValidSeg = has_audseg && aud_seg_allow && (cart_drawer_automount || forceMount());

  if (!has_audseg || isValidSeg) {
    if (cart_drawer) {
      cart_drawer_automount ? initializeCartDrawer(drawer_route) : setWindowMethods();
    } else {
      debugConsole('[Cart 3.0] Cart Drawer is not enabled', 'info', {
        component: 'Cart Drawer',
        details:
          'In order to enable cart, please set the corresponding configurations and necessary feature flags in ShopKeeper.'
      });
    }
  }
};

if (!document.querySelector(SELECTORS.cartDrawer)) {
  if (!skipCartDrawer()) {
    cartDrawerInjection();
  }
}
