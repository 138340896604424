import { skipCartDrawer } from '../events/cart';
import { mountOnIcon } from './mount';
import { isGem } from '../platform/gemini';

import { SELECTORS } from '../utils/constants';
import { isVulcan } from '../utils/helpers';
import { observeMutations } from '../utils/mutations';
import { TENANTS_SAFEGUARDS } from '../platform/tenants';

export const removeLegacyOfferPopup = () => {
  const mutationCallback = () => {
    const popups = document.querySelectorAll('.sop--v2__success-popup');
    popups.forEach((popup: HTMLElement) => {
      popup.remove();
    });
  };

  observeMutations(document.body, () => mutationCallback(), { subtree: true });
};

export const removeLegacyErrorOverlay = () => {
  const mutationCallback = () => {
    const generic = typeof window.generic === 'object' ? window.generic : {};
    generic?.overlay?.hide();
  };

  const maxErrorMessageElement = document.querySelector(SELECTORS.maxErrorMessage);
  const cboxContentElement = document.querySelector(SELECTORS.cbox);
  const element = maxErrorMessageElement || cboxContentElement || document.body;

  return observeMutations(element, () => mutationCallback(), { subtree: true });
};

export const removeLegacyCartDrawer = () => {
  if (skipCartDrawer()) {
    return;
  }
  if (isGem()) {
    TENANTS_SAFEGUARDS['EL']['REMOUNT_ICON'].fn(mountOnIcon);
  }

  const mutationCallback = () => {
    const query = isVulcan()
      ? SELECTORS.legacyCartDrawerVulcan
      : SELECTORS.legacyCartDrawerDrupal.join(',');

    const legacyCartDrawer = document.querySelectorAll(query);

    legacyCartDrawer.forEach((cartDrawer: HTMLElement) => {
      if (isGem()) {
        cartDrawer.style.display = 'none';
      } else {
        cartDrawer.remove();
      }
    });
  };

  window.dispatchEvent(
    new CustomEvent('cart.removeLegacyCart', {
      detail: { removeLegacyCart: true }
    })
  );

  return observeMutations(document?.body, () => mutationCallback(), { subtree: true });
};
