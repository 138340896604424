import { ICartItem } from '../types';
import { SELECTORS } from '../utils/constants';
import { debugConsole } from '../utils/debug';
import { observeMutations } from '../utils/mutations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

const deactivateLegacyCart = () => {
  if ($('.js-gnav-cart').length) {
    $('.js-gnav-cart').removeClass('active');
  }
};

const overrideCartIconLink = () => {
  try {
    if ($('.js-gnav-util-trigger--cart').length) {
      $(document).on('click', '.js-gnav-util-trigger--cart', function () {
        window.location = $(this).attr('href');
        return true;
      });
    }
  } catch (error) {
    debugConsole(
      '[Cart 3.0] Unable to override Cart Icon link',
      'warn',
      { component: 'Cart Icon', details: error },
      error
    );
  }
};

const handleDWAddToCart = (collectionId: number, allItems: ICartItem[] = []) => {
  if (allItems.length > 0) {
    const itemInCart = allItems.find((item) => item.COLLECTION_ID === collectionId);

    if (
      itemInCart &&
      itemInCart.COLLECTION_SUBTYPE === 'EL_DW_DRAMMING' &&
      itemInCart['sku.SKU_BASE_ID']
    ) {
      return itemInCart.sku_string ?? itemInCart['sku.SKU_BASE_ID'];
    }
  }
};

const observeRemountIcon = (mountOnIcon: () => void) => {
  const cartIcon = document.querySelector(SELECTORS.cartIconGemini);

  if (cartIcon) {
    const target = cartIcon.parentNode?.parentNode || document.body;
    observeMutations(target, () => mountOnIcon());
  }
};

export const TENANTS_SAFEGUARDS = {
  AV: {
    LEGACY_CART: {
      fn: deactivateLegacyCart,
      desc: 'When ATB success event is triggered, it tries to enable legacy cart drawer. This function will try to prevent that.'
    },
    CART_ICON_OVERRIDE: {
      fn: overrideCartIconLink,
      desc: 'There is a small hack on Drupal that sets and blocks cart link on cart icon. This will override the cart icon link in case this happens.'
    }
  },
  EL: {
    DOUBLE_WEAR: {
      fn: handleDWAddToCart,
      desc: 'Special handling for Double Wear products (collection). It will return the SKU strings for the two products involved.'
    },
    REMOUNT_ICON: {
      fn: observeRemountIcon,
      desc: 'EL on Gemini updates the cart icon and this causes that the original mounting logic is lost. This will remount the icon.'
    }
  }
};
