export const isVulcan = () => window._platform === 'vulcan';

export const matchLocation = (suppressed: string[], location: string) =>
  !!suppressed.some((path) => location.includes(path));

export const setAttributes = (element: Element, attributes: Record<string, string>) => {
  Object.entries(attributes).forEach(([key, value]) => {
    element.setAttribute(key, value);
  });
};

export const getCookie = (cookieWithValue: string) => {
  return document.cookie
    .split(';')
    .some((item) => item.trim().toUpperCase().startsWith(cookieWithValue));
};

export const debugMode = () => getCookie('VULCANIZE_CART_DEBUG=1');
export const forceMount = () => getCookie('VULCANIZE_CART=1');
