import { debugMode } from './helpers';

export const debugConsole = (
  message: string,
  level = 'info',
  context?: Record<string, string>,
  error?: Error
) => {
  if (debugMode()) {
    const levels = { debug: 0, info: 1, warn: 2, error: 3 };
    const isConcerning = levels[level] > levels['info'] && error;

    if (levels[level] >= levels['info']) {
      // eslint-disable-next-line no-console
      console[level](message, context);
      if (isConcerning && 'DD_RUM' in window) {
        window.DD_RUM?.onReady(() => {
          window.DD_RUM?.addError(error, { message, context });
        });
      }
    }
  }
};
