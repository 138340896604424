export const ALLOWED_ERROR_MSG_KEYS = [
  'allocation.qty_reduced',
  'cart.item_limit',
  'cart.qty_limit.reduced',
  'early_access_item'
];

export const ATB_EVENTS = ['addToCart.success', 'dwAddToCart.success'];
export const ATB_FAILURE_EVENTS = ['addToCart.failure'];

export const CART_CONFIGS = {
  aud_seg_allow: false,
  cart_drawer: false,
  cart_drawer_automount: false,
  has_audseg: false,
  drawer_route: '/cart/v3_0/drawer'
};

export const CONTROLLER_EVENTS = [
  'openCart@window->cart-drawer#open',
  'failCart@window->cart-drawer#fail',
  'fetchCartDrawer@window->cart-drawer#updateContent',
  'closeCart@window->cart-drawer#close'
];

export const DRAWER_ATTRIBUTES = {
  'data-cart-drawer-mode': 'overlay',
  'data-controller': 'cart-drawer',
  'data-cart-drawer-mode-value': 'overlay',
  'data-action': CONTROLLER_EVENTS.join(' ')
};

export const SELECTORS = {
  cartDrawer: 'cart-drawer',
  cbox: '#cboxContent',
  cartIconDrupal: '[data-behavior-cart="icon"]',
  cartIconGemini: '[data-behavior-cart="icon"]',
  cartIconVulcan: '[data-controller*="cart"]',
  countIconNodesOrder: [
    '.site-bag__link-count', // MAC US
    '.utility-nav__cart-count', // EL US - Drupal
    '.gnav-util__icon__cart-count', // EL US - Gemini
    '.header-gnav-cart__count',
    '[class*="utility-nav__cart"]',
    '[data-cart-target="itemsCount"]' // Vulcan
  ],
  itemSelector: 'data-sku-base-id',
  legacyCartDrawerVulcan: '[data-behaviour="cart-drawer"]',
  legacyCartDrawerDrupal: ['[data-behavior-cart="modal"]', '.header-gnav-cart__overlay'],
  maxErrorMessage: '[data-test-id="max_error_message"]',
  offerCode: '[data-offercode]',
  qtySelector: '[data-test-id="cart_product_quantity"]',
  recentAddedItem: 'data-cart-item-sku-id-value'
};

export const SUPPRESSED_PLATFORMS = ['tomfordbeauty'];
export const SUPPRESSED_PAGES = [
  '/checkout/viewcart.tmpl',
  '/cart',
  '/guest-checkout',
  '/checkout',
  '/sign-in',
  '/sign-up'
];

export const WEBCOMPONENTS_CONTROLLERS = ['cart-icon', 'cart-drawer', 'cart-item'];
