import { openCartEvent, failingCartEvent } from '../../events/cart';
import { IEventResultData } from '../../types';
import { sanitizePayload } from '../../../utils/cart';
import { ATB_EVENTS, ATB_FAILURE_EVENTS } from '../../utils/constants';
import { debugConsole } from '../../utils/debug';
import { TENANTS_SAFEGUARDS } from '../tenants';
import { removeLegacyOfferPopup } from '../../actions/remove';
import { processRawATBPayload, processATBPayloadMethods, getErrorMessage } from './mappings';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

const fetchCartDrawer = () => {
  const newCartEvent = new CustomEvent('fetchCartDrawer');
  window.dispatchEvent(newCartEvent);
};

const atbOffer = (offerElement: Element) => {
  try {
    const offerCode = offerElement.getAttribute('data-offercode') || '';
    const eventName = offerCode ? `offer:${offerCode}:applied` : '';

    try {
      removeLegacyOfferPopup();
    } catch {
      // eslint-disable-next-line no-console
      debugConsole('[Cart 3.0] Unable to remove legacy offer popup');
    }

    if (eventName) {
      $(document).on(eventName, () => {
        openCartEvent({ offerCode, quantity: 1 });
      });
    }
  } catch {
    fetchCartDrawer();
  }
};

const mapATBResults = (resultData: IEventResultData) => {
  const isMultiSku = 'ac_results' in resultData && resultData.ac_results.length > 1;
  const isCollection = 'coll_info' in resultData && resultData.coll_info;

  if (isMultiSku && !isCollection) {
    const newCartEvent = new CustomEvent('openCart', {
      detail: {
        atbResponseData: { result: { data: sanitizePayload(resultData) } },
        cartMode: 'modal',
        quantity: 1
      }
    });
    window.dispatchEvent(newCartEvent);

    return;
  }

  const rawResults = resultData.ac_results;

  const { collectionId, skuId, quantity } = rawResults
    ? processRawATBPayload(resultData)
    : processATBPayloadMethods(resultData);

  return { collectionId, quantity, skuId };
};

export const isDrp = () => 'jQuery' in window && typeof window.jQuery === 'function';

export const mountOnVulcanATB = (offerElement: Element | null) => {
  const failATB = (_event: Event, resultData: unknown[]) => {
    const messages = getErrorMessage(resultData);

    failingCartEvent(messages);
  };

  const successATB = (_event: Event, resultData: IEventResultData) => {
    try {
      const atbData = mapATBResults(resultData);

      if (atbData) {
        const { collectionId, quantity, skuId } = atbData;
        openCartEvent({ skuId, quantity, collectionId });
        TENANTS_SAFEGUARDS['AV']['LEGACY_CART'].fn();
      }
    } catch (error) {
      debugConsole(
        '[Cart 3.0] Unable to trigger Drupal ATB cart modal',
        'error',
        { component: 'AddToBag', details: error.message },
        error
      );
    }
  };

  ATB_FAILURE_EVENTS.forEach((atbFailureEvent) => {
    $(window).on(atbFailureEvent, failATB);
    $(document).on(atbFailureEvent, failATB);
  });

  ATB_EVENTS.forEach((atbEvent) => {
    $(window).on(atbEvent, successATB);
    $(document).on(atbEvent, successATB);
  });

  if (offerElement) {
    atbOffer(offerElement);
  }

  TENANTS_SAFEGUARDS['AV']['CART_ICON_OVERRIDE'].fn();
};
