export const observeMutations = (
  target: Node | undefined | null,
  callback: MutationCallback,
  config?: MutationObserverInit
) => {
  const observer = new MutationObserver(callback);
  if (target) {
    observer.observe(target, { childList: true, ...config });
  }

  return observer;
};
