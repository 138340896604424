export default /*css*/ `
  :host {
    --background-dim-color: var(--cart-drawer-bg-dim-color, rgba(0, 0, 0, 0.4));
    --cart-overlay-display-mobile: none;

    --modal-animation: var(--cart-modal-animation, fadeInAnimation 0.5s ease forwards);
    --modal-border: var(--cart-modal-border, 1px solid rgba(0, 0, 0, 0.4));
    --modal-border-radius: var(--cart-modal-border-radius, 0);
    --modal-display: var(--cart-modal-display, flex);
    --modal-height: var(--cart-modal-height, auto);
    --modal-max-height: var(--cart-modal-max-height, 80vh);
    --modal-max-width: var(--cart-modal-max-width, none);
    --modal-min-width: var(--cart-modal-min-width, 358px);
    --modal-opacity: var(--cart-modal-opacity, 0);
    --modal-transform: var(--cart-modal-transform, translate(-50%, -50%));
    --modal-transition: var(--cart-modal-transition, none);
    --modal-width: var(--cart-modal-width, calc(100% - 32px));
    --modal-inset-block-start: var(--cart-modal-inset-block-start, 50%);
    --modal-inset-block-end: var(--cart-modal-inset-block-end, auto);
    --modal-inset-inline-start: var(--cart-modal-inset-inline-start, 50%);
    --modal-inset-inline-end: var(--cart-modal-inset-inline-end, auto);
    --modal-footer-box-shadow: var(--cart-modal-footer-box-shadow, none);
    --modal-hidden-animation: var(--cart-modal-hidden-animation, fadeOutAnimation 0.5s ease forwards);
    --modal-hidden-display: var(--cart-modal-hidden-display, none);
    --modal-handle-display: var(--cart-modal-handle-display, none);
    --overlay-slide-in-animation: var(--cart-overlay-slide-in-animation, slideInFromRight);
    --overlay-slide-out-animation: var(--cart-overlay-slide-out-animation, slideOutToRight);
  }
  .cart-wrapper__overlay ::slotted(cart-items-list) {
    height: 100%;
    min-width:380px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--cart-drawer-items-list-bg, #ffffff);
  }
  .cart-wrapper {
    display: flex;
    font-family: var(--cart-wrapper-font-family, inherit);
    letter-spacing: var(--cart-wrapper-letter-spacing, normal);
    visibility: var(--cart-wrapper-visibility, hidden);
  }
  .cart-wrapper__modal {
    position: fixed;
    inset-block-start: var(--modal-inset-block-start);
    inset-block-end: var(--modal-inset-block-end);
    inset-inline-start: var(--modal-inset-inline-start);
    inset-inline-end: var(--modal-inset-inline-end);
    background-color: white;
    border-radius: var(--modal-border-radius);
    overflow: hidden;
    z-index: 999999;
    transform: var(--modal-transform);
    transition: var(--modal-transition);
    border: var(--modal-border);
    opacity: var(--modal-opacity);
    animation: var(--modal-animation);
    min-width: var(--modal-min-width);
    max-width: var(--modal-max-width);
    width: var(--modal-width);
    height: var(--modal-height);
    max-height: var(--modal-max-height);
    display: var(--modal-display);
    flex-direction: column;
    font-family: var(--cart-drawer-font, inherit);
    outline: none;
  }
  .cart-wrapper__modal ::slotted(cart-items-list) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cart-wrapper__modal.hidden--onload {
    display: none;
  }
  .cart-wrapper__modal.hidden {
    animation: var(--modal-hidden-animation);
  }
  slot[name="background"]::slotted(.cart-wrapper__bg) {
    opacity: 1;
  }
  slot[name="background"].hidden::slotted(.cart-wrapper__bg) {
    visibility: hidden;
    opacity: 0;
  }
  .cart-wrapper__overlay {
    display: var(--cart-overlay-display-mobile);
    flex-direction: column;
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    max-width: 380px;
    height: 100vh;
    background-color: white;
    overflow: hidden;
    z-index: 999999;
    transition: 0.1s;
    animation: var(--overlay-slide-in-animation) 0.2s ease-in-out forwards;
    outline: none;
  }
  .cart-wrapper__overlay.hidden {
    animation: var(--overlay-slide-out-animation) 0.2s ease-in-out forwards;
  }
  ::slotted(.cart-wrapper__bg) {
    inset-inline-start: 0;
    width: 100vw;
    z-index: 999998;
    min-width: 100vw;
    position: fixed;
    top: 0;
    height: 100vh;
    transition: 0.2s ease all;
    background-color: var(--background-dim-color);
  }
  ::slotted(.cart-wrapper__bg.modal),
  ::slotted(.cart-wrapper__bg.cart-modal) {
    top: 0;
    height: 100vh;
  }
  ::slotted(.cart-wrapper__bg.overlay),
  ::slotted(.cart-wrapper__bg.cart-overlay) {
    display: var(--cart-overlay-display-mobile);
    top: 0;
    height: 100vh;
    width: calc(100vw - 380px);
    min-width: calc(100vw - 380px);
  }
  .cart-wrapper__bg-placeholder {
    background-color: var(--background-dim-color);
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    content: '';
    overflow: hidden;
    opacity: 1;
    transition: 0.2s ease all;
    visibility: visible;
    outline: none;
  }
  .cart-wrapper__bg-placeholder.overlay {
    width: 380px;
    z-index: 999999;
    height: 100vh;
  }
  .cart-wrapper__bg-placeholder.hidden {
    visibility: hidden;
    opacity: 0;
  }
  .cart-wrapper__button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: var(--cart-button-height);
    width: var(--cart-button-width);
    color: var(--cart-button-fg);
    background-color: var(--cart-button-bg);
    border: 1px solid var(--cart-button-border-color);
    border-radius: var(--cart-button-border-radius);
    font-size: var(--cart-button-font-size);
  }
  .cart-drawer__fullscreen {
    border-radius: 0;
    height: 100dvh;
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideOutToLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  @keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
  }
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
    }
  }
  @media(min-width: 768px) {
    :host {
      --modal-max-width: var(--cart-modal-max-width, 450px);
    }
  }
  @media(min-width: 1024px) {
    :host {
      --cart-overlay-display-mobile: flex;
    }
  }
`;
