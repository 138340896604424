import { skipCartDrawer } from '../events/cart';
import { ICartDrawerInjection } from '../types';
import { CART_CONFIGS } from './constants';
import { debugConsole } from './debug';

export const fetchCartConfigs: () => Promise<ICartDrawerInjection> = async () => {
  if (skipCartDrawer()) {
    return {};
  }

  const countryAndLanguage = /^\/([a-z]{2})\/([a-z]{2})(\/|$)/i;
  const languageOnly = /^\/([a-z]{2})(\/|$)/i;

  const pathname = window.location.pathname;
  const countryLangPair = countryAndLanguage.exec(pathname);

  const lang = countryLangPair ? countryLangPair[2] : languageOnly.exec(pathname)?.[1];
  const country = countryLangPair ? countryLangPair[1] : null;

  const url =
    '/cart/v3_0/config' +
    (lang ? '?language=' + lang : '') +
    (country ? '&country=' + country : '');

  try {
    const response = await fetch(url);

    return response.json();
  } catch (error) {
    debugConsole(
      `Could not fetch cart configs from ${url}`,
      'error',
      { component: 'configs' },
      error
    );

    return CART_CONFIGS;
  }
};
